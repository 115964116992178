import { Component, OnInit, Input } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {
  // @Input() title: string;
  @Input() information: string;
  @Input() positiveButton: string;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    // this.title = "Information";
  }

}
