import {Component, OnInit} from '@angular/core';
import {Auth} from 'aws-amplify';
import {environment} from '@environments/environment';
import {StoreService} from '@app/shared/services/store.service';

import {ActivatedRoute, Router} from '@angular/router';
import {HhtApiService} from '@app/shared/services/hht-api.service';

export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    iconAtv: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    {
        path: 'url/short',
        title: 'URL SHORTENER',
        icon:'../../assets/img/icons/icon-itinerary.svg',
        iconAtv:'../../assets/img/icons/icon-itinerary-selected.png',
        class: ''
    },
    {
        path: null,
        title: 'ANALYTICS',
        icon:'../../assets/img/icons/icon-mmd.png',
        iconAtv:'../../assets/img/icons/icon-mmd-selected.svg',
        class: ''
    },
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    appVersion = environment.app_version;

    constructor(private storeService: StoreService,
                private router: Router,
                private route: ActivatedRoute,
                private hhtApiService: HhtApiService){
    }


    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }

    onLogOut() {
        Auth.signOut()
        localStorage.clear();
        this.storeService.clear();
        window.location.replace(environment.signOut);
    }

    onChangePassword(){
        this.router.navigate(['url/change-password'],
            {
                //relativeTo: this.route,

            });
    }
}
