import { Component, DoCheck, OnInit} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/shared/services/guards/auth.service';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { Hub, Auth } from 'aws-amplify';

@Component({
  selector: 'app-short',
  templateUrl: './itinerary-list.component.html',
  styleUrls: ['./itinerary-list.component.css']
})
export class ItineraryListComponent implements OnInit, DoCheck {
  isLoading: boolean = true;

  // sort
  page=1;
  prevPage=1;
  defPage=1;
  public toggleSort: boolean = true;
  public isSortedAgain: string;
  public selectedSort: string;

  p: number = 1;
  public searchText : string;

  itineraryArray = [];

  userAccountInfo = [];

  searchString = '';
  limit = 10;
  offset= 0;
  totalCount: number;

  monthParse = {
    "01":"January",
    "02":"February",
    "03":"March",
    "04":"April",
    "05":"May",
    "06":"June",
    "07":"July",
    "08":"August",
    "09":"September",
    "10":"October",
    "11":"November",
    "12":"December"
  }

  monthOptions = [
    {
      month: 'January',
      value: '01'
    },
    {
      month: 'February',
      value: '02'
    },
    {
      month: 'March',
      value: '03'
    },
    {
      month: 'April',
      value: '04'
    },
    {
      month: 'May',
      value: '05'
    },
    {
      month: 'June',
      value: '06'
    },
    {
      month: 'July',
      value: '07'
    },
    {
      month: 'August',
      value: '08'
    },
    {
      month: 'September',
      value: '09'
    },
    {
      month: 'October',
      value: '10'
    },
    {
      month: 'November',
      value: '11'
    },
    {
      month: 'December',
      value: '12'
    }
  ]
  convertMonth:number;
  selectedMonth:string;
  yearOptions = [];
  selectedYear:string;
  userEmail:string;
  userName:string;

  constructor(private router: Router, 
    private route: ActivatedRoute,
    private hhtApiService: HhtApiService,
    private authService: AuthService
    ) { }
    

  ngOnInit() {

    //async function onSignOutClick() {
      //await Auth.signOut()
      //    .then(data => console.log(data))
      //    .catch(err => console.log(err));
    //  console.log(Auth.signOut({global:true}));
    //}
    //onSignOutClick()
    
    var d = new Date();
    var currentMonth = d.getMonth();
    this.convertMonth = (currentMonth + 1);
    var month_ = "";
    if (this.convertMonth.toString().length == 1){
      month_ = "0" + this.convertMonth.toString();
    }
    else {
      month_ = this.convertMonth.toString()
    }

    this.selectedMonth =  month_;
    var currentYear = d.getFullYear();
    var startYear = currentYear - 5;
    for(let i=0; i<6; i++){
      let yearOption = startYear + i;
      this.yearOptions.push(yearOption)
    }

    this.selectedYear = currentYear.toString();
    this.hhtApiService.getAllItineraries(this.searchString, this.limit, this.offset, this.selectedYear, this.selectedMonth).subscribe((res: any[])=>{    
      this.itineraryArray = res['body']['itineraries'];
      this.totalCount = res['body']['details'][0]['total_count'];
      console.log(this.totalCount)
      console.log(this.itineraryArray)
      this.isLoading = false;
    }) 
   
    
    

    Auth.currentSession().then( data => {
      this.userEmail = data.getIdToken().payload.email;
      this.userName = data.getIdToken().payload['cognito:username'];
      //this.myService.changeMessage(this.userName)
      console.log("Email :" + this.userEmail)
      console.log("UserName :" + this.userName)
      
      this.hhtApiService.getUserAccountInfo(this.userName).subscribe((res: any[])=>{  
        console.log(res['body']['UserInfo'])
        this.userAccountInfo = res['body']['UserInfo']
        
        if(this.userAccountInfo.length > 0){       
          console.log('Password Expiration Date:' + res['body']['UserInfo'][0]['expirationDate'])
          const d = new Date();
          let year = d.getFullYear();
          let month = d.getMonth() + 1;
          let day = d.getDate();
          console.log(year + '-' + month + '-' + day)

          if (new Date(res['body']['UserInfo'][0]['expirationDate']) >= d) {

          }
          else {

          }
          
        }
        else {
          
        }
      })
    });

    
  }

  ngDoCheck(){    
    if(typeof this.searchText !== "undefined" && this.searchString !== this.searchText){
      this.searchText = this.searchText.trim();
      this.searchString = this.searchText;
      this.itineraryArray = [];
      this.totalCount = 0;
      this.hhtApiService.getAllItineraries(this.searchString, this.limit, this.offset, this.selectedYear, this.selectedMonth).subscribe((res: any[])=>{      
        if(typeof res['body']['itineraries'] === "object"){
          this.itineraryArray = res['body']['itineraries'];
          this.totalCount = res['body']['details'].length>0 ? res['body']['details'][0]['total_count']: 0;
        }        
      })
    }
  }

  monthChanged(month){
    this.isLoading = true;
    this.hhtApiService.getAllItineraries(this.searchString, this.limit, this.offset, this.selectedYear, month).subscribe((res: any[])=>{    
      console.log(res['body']) 
      this.itineraryArray = res['body']['itineraries'];
      this.totalCount = res['body']['details'][0]['total_count'];
      console.log(this.totalCount)
      console.log(this.itineraryArray)
      this.isLoading = false;
    }) 
  }

  yearChanged(year){
    this.isLoading = true;
    this.hhtApiService.getAllItineraries(this.searchString, this.limit, this.offset, year, this.selectedMonth).subscribe((res: any[])=>{    
      console.log(res['body']) 
      this.itineraryArray = res['body']['itineraries'];
      this.totalCount = res['body']['details'][0]['total_count'];
      console.log(this.totalCount)
      console.log(this.itineraryArray)
      this.isLoading = false;
    }) 
  }

  onIteneraryRowClick(item) {
    console.log(item)
    this.router.navigate(['url/itinerary-list/selected-itinerary'],
    { 
      //relativeTo: this.route,
      queryParams: {
      'id': item.itineraryNumber,
      'readings': item.readings,
      'deviceCode':item.device_code,
      'readerCode':item.center_code,
      'month':item.month,
      'year':item.YEAR
      }
    });
  }

  onSort(param: string) {
    let currentIdx = (this.defPage - 1) * this.limit
    let currentList = this.itineraryArray.slice(currentIdx, currentIdx + this.limit)

    if (this.prevPage !== this.page){
      this.isSortedAgain = ''
      this.prevPage = this.page
      this.toggleSort = true;
    }
    
    if (param === this.isSortedAgain) {
      this.isSortedAgain = param

      if (this.selectedSort === 'asc'){
        this.selectedSort = 'desc'
        this.toggleSort = !this.toggleSort
      } else if (this.selectedSort === 'desc'){
        this.selectedSort = ''
        this.toggleSort = !this.toggleSort
      } else {
        this.selectedSort = 'asc'
      }
    } else {
      this.isSortedAgain = param
      this.selectedSort = 'asc'
      this.toggleSort = true
    }

    if (this.selectedSort === 'asc' || this.selectedSort === 'desc'){
      if (this.toggleSort) {
        currentList.sort((a: any, b: any) => {          
          let textA = a[param] === null ? null : a[param].toString().toUpperCase();
          let textB = b[param] === null ? null : b[param].toString().toUpperCase();
          if (textA < textB) { return -1; }
          if (textA > textB) { return 1; }
          if (textA === null) { return 1; }
          if (textB === null) { return -1; }
          return 0;
        })
      } else {
        currentList.sort((a: any, b: any) => { 
          let textA = a[param] === null ? null : a[param].toString().toUpperCase();
          let textB = b[param] === null ? null : b[param].toString().toUpperCase();
          if (textA < textB) { return 1; }
          if (textA > textB) { return -1; }
          return 0;
        })
      }    
      Array.prototype.splice.apply(this.itineraryArray, [currentIdx, currentList.length].concat(currentList));
    }
  }

  onPageChange(event){
    this.prevPage = this.page;
    this.page = event;
    this.selectedSort = '';
    const offset = (event - 1) * this.limit;
    this.isLoading = true;
    this.hhtApiService.getAllItineraries(this.searchString, this.limit, offset, this.selectedYear, this.selectedMonth).subscribe((res: any[])=>{      
      this.itineraryArray= res['body']['itineraries'];
      console.log(this.itineraryArray)
      this.isLoading = false;
    }) 
  }
  
  omit_special_char(event)
  {   
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
  }

  
}
