import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HhtMainComponent } from './hht-main/hht-main.component';
import { RouterModule } from '@angular/router';
import { hhtRoutes } from './hht-routing.module';
import { ItineraryComponent } from './itinerary/itinerary.component';
import { MasterMeterDataComponent } from './master-meter-data/master-meter-data.component';
// import { BrowserModule } from '@angular/platform-browser';
import { FormsModule ,ReactiveFormsModule} from '@angular/forms'
import { ItineraryListComponent } from './itinerary-list/itinerary-list.component';
// import { AgmCoreModule } from '@agm/core';
// AgmCoreModule.forRoot({
//   apiKey: 'AIzaSyBVQTVxw-YgCItkjtgnSTJjFRLs90KyswE'
// })
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MeterComponent } from './meter/meter.component';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { ItineraryMapComponent } from './itinerary-map/itinerary-map.component';

import { SharedModule } from '@app/shared/shared.module';
import { ItineraryMapDetailsComponent } from './itinerary-map-details/itinerary-map-details.component';
import { ItineraryDetailsComponent } from './itinerary-details/itinerary-details.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { SearchFilterPipe } from '../../shared/pipes/search-filter.pipe'
import { TableFilterPipe } from '../../shared/pipes/table-filter.pipe';
import { StoreService } from '@app/shared/services/store.service';
import { ChangePasswordComponent } from './change-password/change-password.component';

@NgModule({
  declarations: [
    HhtMainComponent, 
    ItineraryComponent, 
    MasterMeterDataComponent, 
    ItineraryListComponent, 
    MeterComponent, 
    ItineraryMapComponent, ItineraryMapDetailsComponent, ItineraryDetailsComponent,
    SearchFilterPipe,
    TableFilterPipe,
    ChangePasswordComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(hhtRoutes),
    NgxPaginationModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    AutocompleteLibModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteLibModule,
    SharedModule
  ],
  providers: [
    HhtApiService,
    StoreService
  ]
})
export class HhtModule { }
