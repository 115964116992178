import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { Meter } from '../models/Meter';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-itinerary-map-details',
  templateUrl: './itinerary-map-details.component.html',
  styleUrls: ['./itinerary-map-details.component.css'],
})
export class ItineraryMapDetailsComponent implements OnInit, OnChanges {

  listOfMeters: any;
  readings:any; 
  clickedItem: any;
  isActive: boolean;
  isDeselectMeter: boolean;


  @Input() list: Array<Meter>;
  @Input() deselectMeter: boolean;
  @Output() selectedMeter = new EventEmitter<any>();

  constructor(private activatedRoute: ActivatedRoute,) { }

  //detects changes from list input
  ngOnChanges(changes: SimpleChanges){
    for (const propName in changes) {
      const change = changes[propName];      
      if(typeof change.currentValue === "object"){
        this.listOfMeters = change.currentValue;
      }else if(typeof change.currentValue === "boolean"){
        this.isDeselectMeter = change.currentValue;
        if(this.isDeselectMeter){
          this.onClick(undefined, -1);
        }
      }
      //console.log("listOfMeters",this.listOfMeters);
      //console.log("change.currentValue type", typeof change.currentValue);
    }
  }

  ngOnInit() {
    this.isActive = false;
    this.isDeselectMeter = this.deselectMeter;
    this.activatedRoute.queryParams.subscribe(params => {
      this.readings = params.readings;
    })
  }

  onClick(meterDetails, index){
    this.clickedItem = index;   
    if(typeof meterDetails === "object"){
      this.isActive = true;
      this.selectedMeter.next(meterDetails);
    }else{
      this.isActive = false;
    }   
  }

}
