import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Auth} from 'aws-amplify';
import {environment} from '@environments/environment';
import {StoreService} from '@app/shared/services/store.service';

export interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}

export const ROUTES: RouteInfo[] = [
  {
    path: 'url/short',
    title: 'Link Shortener',
    icon:'../../assets/img/icons/icon-itinerary.svg',
    class: ''
  },
  {
    path: 'url/meter-list',
    title: 'Master Meter Data',
    icon:'../../assets/img/icons/icon-mmd.png',
    class: ''
  }
];


@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']

})
export class AdminLayoutComponent implements OnInit {

  constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private storeService: StoreService

  ) { }

  ngOnInit() {
  }


  itenerariesOnClick() {
    console.log('itenerariesOnClick')
    this.router.navigate(['url/i'])
  }

  masterMeterDataOnClick() {
    this.router.navigate(['url/mmd'])
  }

  itenerariesListOnClick(){
    this.router.navigate(['url/il'])
  }

  onLogOut() {
    Auth.signOut()
    localStorage.clear();
    window.location.replace(environment.signOut);
    this.storeService.clear();
  }
}
