import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import { environment } from '@environments/environment';

@Injectable()
export class RoleGuard implements CanActivate {

  route: any;
  constructor(
    private router:Router
    ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    
    this.router.navigateByUrl(environment.homepage)

    return false
  }


} 