import { Component, DoCheck, OnInit } from '@angular/core';
import { HhtApiService } from '@app/shared/services/hht-api.service';
import { StoreService } from '@app/shared/services/store.service';
import { ModalService } from '@shared/services/modal.service';
import { Hub, Auth } from 'aws-amplify';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-master-meter-data',
  templateUrl: './master-meter-data.component.html',
  styleUrls: ['./master-meter-data.component.scss']
})
export class MasterMeterDataComponent implements OnInit, DoCheck {
  isLoading: boolean = true;

  p: number = 1;
  public strLatLong: string;
  public strActualLatLong: string;
  public searchText : string;
  isLocationChanged : boolean;
  updatedMeterNumber : string;
  
  meterArray= [];
  searchString = '';
  limit = 10;
  offset= 0;
  totalCount: number;

  //sort
  page=1;
  prevPage=1;
  defPage=1;
  public toggleSort: boolean = true;
  public isSortedAgain: string;
  public selectedSort: string;
  userAccountInfo = [];
  userEmail:string;
  userName:string;

  constructor(
    private hhtApiService: HhtApiService,
    private modalService: ModalService,
    private storeService: StoreService,
    private router: Router, 
  ) { }

  ngOnInit() {
    this.isLocationChanged = false;
    this.hhtApiService.getAllMeters(this.searchString, this.limit, this.offset).subscribe((res: any[])=>{      
      this.meterArray= res['body']['results'];
      this.totalCount= res['body']['details'][0]['total_count'];

      this.isLoading = false;
    })

    Auth.currentSession().then( data => {
      this.userEmail = data.getIdToken().payload.email;
      this.userName = data.getIdToken().payload['cognito:username'];
      //this.myService.changeMessage(this.userName)
      console.log("Email :" + this.userEmail)
      console.log("UserName :" + this.userName)
      
      this.hhtApiService.getUserAccountInfo(this.userName).subscribe((res: any[])=>{  
        console.log(res['body']['UserInfo'])
        this.userAccountInfo = res['body']['UserInfo']
        
        if(this.userAccountInfo.length > 0){       
          console.log('Password Expiration Date:' + res['body']['UserInfo'][0]['expirationDate'])
          const d = new Date();
          let year = d.getFullYear();
          let month = d.getMonth() + 1;
          let day = d.getDate();
          console.log(year + '-' + month + '-' + day)

          if (new Date(res['body']['UserInfo'][0]['expirationDate']) >= d) {

          }
          else {

          }
          
        }
        else {

          
        }
      })
    });
  }
  
  onUpdate(item, isUpdate){
    this.strLatLong = item.Longitude_Latitude.split(",", 2);
    this.strActualLatLong = item.Actual_Longitude_Latitude.split(",", 2);
    console.log('this.strLatLong',this.strLatLong);
    let meterDetails = {
      lat: this.strLatLong[1],
      lng: this.strLatLong[0],
      actualLat: this.strActualLatLong[1],
      actualLng: this.strActualLatLong[0],
      meterNumber: item.meter_number,
      consumptionType: item.meter_consumption_type,
    }
    console.log('meterDeails', meterDetails)
    this.modalService.openMapModal(meterDetails, isUpdate);
  }
  

  onSort(param: string) {
    let currentIdx = (this.defPage - 1) * this.limit
    let currentList = this.meterArray.slice(currentIdx, currentIdx + this.limit)
    
    if (this.prevPage !== this.page){
      this.isSortedAgain = ''
      this.prevPage = this.page
      this.toggleSort = true;
    }
    
    if (param === this.isSortedAgain) {
      this.isSortedAgain = param

      if (this.selectedSort === 'asc'){
        this.selectedSort = 'desc'
        this.toggleSort = !this.toggleSort
      } else if (this.selectedSort === 'desc'){
        this.selectedSort = ''
        this.toggleSort = !this.toggleSort
      } else {
        this.selectedSort = 'asc'
      }
    } else {
      this.isSortedAgain = param
      this.selectedSort = 'asc'
      this.toggleSort = true
    }

    if (this.selectedSort === 'asc' || this.selectedSort === 'desc'){
      if (this.toggleSort) {
        currentList.sort((a: any, b: any) => {          
          let textA = a[param] === null ? null : a[param].toString().toUpperCase();
          let textB = b[param] === null ? null : b[param].toString().toUpperCase();
          if (textA < textB) { return -1; }
          if (textA > textB) { return 1; }
          if (textA === null) { return 1; }
          if (textB === null) { return -1; }
          return 0;
        })
      } else {
        currentList.sort((a: any, b: any) => { 
          let textA = a[param] === null ? null : a[param].toString().toUpperCase();
          let textB = b[param] === null ? null : b[param].toString().toUpperCase();
          if (textA < textB) { return 1; }
          if (textA > textB) { return -1; }
          return 0;
        })
      }    
      Array.prototype.splice.apply(this.meterArray, [currentIdx, currentList.length].concat(currentList));
    }
  }

  onPageChange(event){
    this.prevPage = this.page;
    this.page = event;
    this.selectedSort = '';
    const offset = (event - 1) * this.limit;
    this.isLoading = true;

    this.hhtApiService.getAllMeters(this.searchString, this.limit, offset).subscribe((res: any[])=>{      
      this.meterArray= res['body']['results'];
      console.log(this.meterArray)
      this.isLoading = false;
    })
  }

  ngDoCheck(){   
    if(this.storeService.get('isLocationUpdated')){
      this.isLocationChanged = true;
      this.hhtApiService.getAllMeters(this.searchString, this.limit, this.offset).subscribe((res: any[])=>{      
        this.meterArray= res['body']['results'];
      })
      this.storeService.set('isLocationUpdated', false);
      this.updatedMeterNumber = this.storeService.get('updatedMeterNumber');

      setTimeout(() => {
        this.isLocationChanged = false;
      }, 3000);      
    }

    if(typeof this.searchText !== "undefined" && this.searchString !== this.searchText){
      this.searchText = this.searchText.trim();
      this.searchString = this.searchText;
      this.meterArray = [];
      this.totalCount = 0;
      this.hhtApiService.getAllMeters(this.searchString, this.limit, this.offset).subscribe((res: any[])=>{      
        if(typeof res['body']['results'] === "object"){
          this.meterArray = res['body']['results'];
          this.totalCount = res['body']['details'].length>0 ? res['body']['details'][0]['total_count']: 0;
        } 
      })
    }
  }

  isNotForUpdate(item): boolean{
    const lnglat = item.Longitude_Latitude!=null ? item.Longitude_Latitude.split(",", 2):["",""];
    const lng = lnglat[0]!=null && lnglat[0].trim().length !== 0 ? lnglat[0].trim(): "A";
    const lat = lnglat[1]!=null && lnglat[1].trim().length !== 0 ? lnglat[1].trim(): "B";
    return  item.for_location_update == 0 || 
            item.for_location_update == null ||
            item.Longitude_Latitude == null ||
            isNaN(Number(lng)) ||
            isNaN(Number(lat));
  }

  omit_special_char(event)
  {   
    var k;  
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
  }

}
